import {
    faBriefcase,
    faComments,
    faDatabase,
    faDice,
    faEnvelope,
    faPuzzlePiece,
    faRightFromBracket,
    faUsersViewfinder,
    faHandshakeAngle,
    faVolumeLow,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Button,
    ButtonIcon,
    ButtonText,
    NavbarDivider,
    Sidebar,
    SidebarLayout,
} from 'devlink'
import { characterStore, Team } from 'entities/character/character.model'
import { sessionQueries } from 'entities/session'
import { ReactElement, useEffect, useLayoutEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { pathKeys } from 'shared/lib/react-router'

export function GenericLayout() {
    const location = useLocation()
    const isGuest = location.pathname.startsWith(pathKeys.login())

    return <>{isGuest ? <GuestNavigation /> : <UserNavigation />}</>
}

export function GuestLayout() {
    return (
        <>
            <GuestNavigation />
        </>
    )
}

export function NakedLayout() {
    return <Outlet />
}

function UserNavigation() {
    return (
        <>
            <SidebarLayout
                slotSidebarLayout={
                    <>
                        <SidebarContainer />
                        <Outlet />
                    </>
                }
            />
        </>
    )
}

function GuestNavigation() {
    return <Outlet />
}

function SidebarContainer() {
    const location = useLocation()
    const navigate = useNavigate()

    const initialTeam = {
        name: '',
        is_owner: 0,
        is_admin: 0,
        can_manage_models: 0,
        can_manage_npcs: 0,
        can_manage_datasets: 0,
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0,
        valid_until: '',
    }
    const [selectedTeam, setSelectedTeam] = useState<Team>(characterStore.getState().currentTeam || initialTeam)

    useLayoutEffect(() => {
        const setComponentStateFromStore = (state: any) => {
            setSelectedTeam(state.currentTeam || initialTeam);
        };

        // Manually set the initial state from the store
        const initialState = characterStore.getState();
        setComponentStateFromStore(initialState);

        // Subscribe to future store changes
        const unsubscribe = characterStore.subscribe((state) => {
            if (state.npcs && state.currentTeam && state.images && state.audios) {
                setComponentStateFromStore(state);
            }
        });

        // Cleanup function to unsubscribe
        return () => {
            unsubscribe();
        };

    }, [])

    const navlinksTop = [
        {
            label: 'Voice',
            isCurrent: location.pathname.startsWith(pathKeys.voice()),
            icon: faVolumeLow,
            to: pathKeys.voice(),
        },
        {
            label: 'Dialogue',
            isCurrent: location.pathname.startsWith(pathKeys.dialog()),
            icon: faComments,
            to: pathKeys.dialog(),
        },
        {
            label: 'Roll a Dice',
            isCurrent: location.pathname.startsWith(pathKeys.roll()),
            icon: faDice,
            to: pathKeys.roll(),
        },
        {
            label: 'Game Helper',
            isCurrent: location.pathname.startsWith(pathKeys.gamehelper()),
            icon: faHandshakeAngle,
            to: pathKeys.gamehelper(),
        },
        {
            label: 'Animation',
            isCurrent: location.pathname.startsWith(pathKeys.animation()),
            icon: faUsersViewfinder,
            to: pathKeys.animation(),
        },
    ]
    const initialNavlinksBottom = [
        {
            label: 'Dataset',
            isCurrent: location.pathname.startsWith(pathKeys.dataset()),
            icon: faDatabase,
            to: pathKeys.dataset(),
        },
        {
            label: 'Integration',
            isCurrent: location.pathname.startsWith(pathKeys.integration()),
            icon: faPuzzlePiece,
            to: pathKeys.integration(),
        },
        {
            label: 'Admin',
            isCurrent: location.pathname.startsWith(pathKeys.admin()),
            icon: faBriefcase,
            to: pathKeys.admin(),
        },
        {
            label: 'Contact',
            isCurrent: location.pathname.startsWith(pathKeys.contact()),
            icon: faEnvelope,
            to: pathKeys.contact(),
        },
    ]

    const [navlinksBottom, setNavlinksBottom] = useState(initialNavlinksBottom)

    useEffect(() => {
        // Update `isCurrent` dynamically based on the location
        const updatedNavlinksBottom = initialNavlinksBottom
            .filter((navlink) => selectedTeam.is_admin || navlink.to !== pathKeys.admin())
            .map((navlink) => ({
                ...navlink,
                isCurrent: location.pathname.startsWith(navlink.to),
            }));
        
        setNavlinksBottom(updatedNavlinksBottom);
    }, [location.pathname, selectedTeam])

    const { mutate: logout, isPending: isLogoutPending } = sessionQueries.useLogoutMutation()

    return (
        <Sidebar
            slotSidebarNavlinks={
                <>
                    {navlinksTop.map((navlink) => (
                        <Button
                            key={navlink.to}
                            rpButton={{
                                style: {
                                    cursor: `${navlink.isCurrent ? 'default' : 'pointer'}`,
                                },
                                className: `button is-navlink ${navlink.isCurrent ? '' : 'is-secondary'}`,
                                onClick: (e: React.MouseEvent) => {
                                    e.preventDefault()
                                    if (navlink.isCurrent) return
                                    navigate(navlink.to)
                                },
                            }}
                            slotButton={
                                <div className="is-navlink">
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={navlink.icon}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={navlink.label} />
                                </div>
                            }
                        />
                    ))}

                    <NavbarDivider />

                    {navlinksBottom.map((navlink) => (
                        <Button
                            key={navlink.to}
                            rpButton={{
                                style: {
                                    cursor: `${navlink.isCurrent ? 'default' : 'pointer'}`,
                                },
                                className: `button is-navlink ${navlink.isCurrent ? '' : 'is-secondary'}`,
                                onClick: (e: React.MouseEvent) => {
                                    e.preventDefault()
                                    if (navlink.isCurrent) return
                                    navigate(navlink.to)
                                },
                            }}
                            slotButton={
                                <div className="is-navlink">
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={navlink.icon}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={navlink.label} />
                                </div>
                            }
                        />
                    ))}
                </>
            }
            slotNavbarLogout={
                <>
                    {isLogoutPending ? (
                        <img
                            src="/images/xandi_running.gif"
                            alt="xandi running"
                            style={{ width: '36px' }}
                        />
                    ) : (
                        <>
                            <NavbarDivider />
                            <Button
                                rpButton={{
                                    className: 'button is-transparent is-navlink',
                                    onClick: (e: React.MouseEvent) => {
                                        e.preventDefault()
                                        logout()
                                    },
                                }}
                                slotButton={
                                    <div className="is-navlink">
                                        <FontAwesomeIcon
                                            icon={faRightFromBracket}
                                            size="2x"
                                        />
                                        <ButtonText buttonText="Log out" />
                                    </div>
                                }
                            />
                            <a
                                href="https://www.xandimmersion.com/terms-conditions"
                                className="button is-transparent is-navlink"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="is-navlink underline">
                                    <ButtonText buttonText="Terms & Conditions" />
                                </div>
                            </a>
                        </>
                    )}
                </>
            }
        />
    )
}
