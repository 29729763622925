import { LoaderGenerating, PageHeaderAdmin } from 'devlink'
import { characterQueries } from 'entities/character'
import { characterStore } from 'entities/character/character.model'
import { sessionQueries } from 'entities/session'
import { AdminContext } from 'pages/admin/admin-page.model'
import { useContext, useLayoutEffect, useState } from 'react'
import { sessionStore } from '../../entities/session/session.model'

type TeamOption = {
    t: string
    v: string
}

export function PageHeaderAdminContainer() {
    const [isFormFilled, setIsFormFilled] = useState(false)
    const [teamOptions, setTeamOptions] = useState<TeamOption[]>([])
    const [currentTeam, setCurrentTeam] = useState<
        TeamOption | null | undefined
    >()
    const [showCopyPopup, setShowCopyPopup] = useState(false)
    const [isDropdownProcessing, setIsDropdownProcessing] = useState(false)

    const {
        adminStore,
        requestUpdate,
        setRequestUpdate,
    } = useContext(AdminContext)


    useLayoutEffect(() => {
        const currentState = characterStore.getState()
        if (currentTeam && (currentState.currentTeam?.name !== currentTeam?.t )) {
            getKeys()
            getNpcs()
            getImages()
            getAudios()

            setCurrentTeam(
                teamOptions.find((team) => team.t === currentState.currentTeam?.name) ||
                    null
            )
            return
        }
        if (!currentState.npcs){
            getNpcs()
        }
        if (!currentState.images){
            getImages()
        }
        if (!currentState.audios){
            getAudios()
        }
    }, [currentTeam, requestUpdate])
    
    const {
        mutate: getKeys,
    } = sessionQueries.useGetKeysMutation(false)

    const { 
        mutate: getNpcs,
    } = characterQueries.useNpcsService()

    
    const { 
        mutate: getBackground,
    } = characterQueries.useBackgroundService()

    const { 
        mutate: getImages,
    } = characterQueries.useImageListService()

    const {
        mutate: getAudios,
    } = characterQueries.useAudioListService()

    useLayoutEffect(() => {
        const currentState = sessionStore.getState()
        setTeamOptions(
            currentState.teams?.map((team, index) => ({
                t: team.name,
                v: index.toString(),
            })) || []
        )
    }, [])

    useLayoutEffect(() => {
        if (!teamOptions || teamOptions.length === 0) return
        const currentState = characterStore.getState()
        setCurrentTeam(
            teamOptions.find((team) => team.t === currentState.currentTeam?.name) ||
                null
        )
    }, [teamOptions])

    return (
        <PageHeaderAdmin
            rpTeamsDropdown={{
                
                value: currentTeam?.v || '',
                name: currentTeam?.t || '',
                options: teamOptions,
                onChange: (event: any) => {
                    const team = teamOptions.find(
                        (team) => team.v === event.target.value
                    )
                    getKeys()
                    getNpcs()
                    getImages()
                    setCurrentTeam(team)
                    setIsDropdownProcessing(true) 
                    setTimeout(() => setIsDropdownProcessing(false), 2000) 
                    characterStore.getState().updateCurrentTeam(
                        sessionStore.getState().teams?.find(
                            (t) => t.name === team?.t
                        ) || ''
                    )

                },
            }}
            rpCopyKeys={{
                onClick: (e: Event) => {
                    e.preventDefault()
                    const currentState = sessionStore.getState()
                    const keys = currentState.keys

                    if (!keys) return
                    type KeyObject = {
                        [key: string]: string
                    }

                    const transformedKeys: KeyObject = Object.entries(
                        keys
                    ).reduce((acc: KeyObject, [key, value]) => {
                        acc[key] = value.key
                        return acc
                    }, {})

                    // copy to clipboard
                    navigator.clipboard.writeText(
                        JSON.stringify(transformedKeys, null, 2)
                    )

                    // Show popup
                    setShowCopyPopup(true)
                    setTimeout(() => setShowCopyPopup(false), 1000) // Hide after 1 second
                },
            }}
            slotGenerationProcessing={
                isDropdownProcessing && <LoaderGenerating />
            }
            showCopyPopup={showCopyPopup}
            showDropdown={teamOptions.length > 1} 

        />
    )
}
