import React from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";

export function PopupRollRestart({
  as: _Component = _Builtin.Block,
  visibility = true,
  rpClosePopUp = {},
  rpButtonCancel = {},
  rpButtonConfirm = {},
}) {
  return visibility ? (
    <_Component className="popup-overlay" tag="div"{...rpClosePopUp}>
      <_Builtin.Block
        className="popup-box margin-medium rounded-8 overflow-scroll-navbar"
        tag="div"
      >
        <_Builtin.Block
          className="popup-top background-color-blue200 padding-xsmall"
          tag="div"
        >
          <_Builtin.Block
            className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            {""}
          </_Builtin.Block>
          <_Builtin.Heading className="text-align-center" tag="h2">
            {"Restart roll generation ?"}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup" tag="div" {...rpClosePopUp}>
            {""}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className="popup-body background-blue-150 padding-small"
          tag="div"
        >
          <_Builtin.Paragraph className="text-align-center text-color-light-grey margin-bottom margin-xxsmall">
            {
              "You are about to restart the roll. All rolls will be replaced by new ones."
            }
          </_Builtin.Paragraph>
          <_Builtin.Block
            className="text-weight-bold text-align-center"
            tag="div"
          >
            {"Are you sure ?"}
          </_Builtin.Block>
          <_Builtin.Block className="button-group padding padding-xsmall" tag="div">
            <_Builtin.Link
              className="button is-large is-transparent"
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
              {...rpButtonCancel}
            >
              <_Builtin.Block className="slot-button" tag="div">
                <ButtonIcon buttonIconText="" />
                <ButtonText buttonText="Cancel" />
              </_Builtin.Block>
            </_Builtin.Link>
            <_Builtin.Link
              className="button is-large"
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
              {...rpButtonConfirm}
            >
              <_Builtin.Block className="slot-button" tag="div">
                <ButtonIcon buttonIconText="" />
                <ButtonText buttonText="Restart" />
              </_Builtin.Block>
            </_Builtin.Link>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}
