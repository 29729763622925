import { sessionModel } from 'entities/session'
import { gamehelperBaseUrl } from 'shared/api/ximmersion'
import { createFormMutation } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { CreateGamehelperLineResponseSchema, CreateGamehelperUploadPDFResponseSchema } from './gamehelper.contracts'
import { mapCreateGamehelperLine, mapCreateGamehelperUpload } from './gamehelper.lib'
import { CreateGamehelperLineDto, CreateGamehelperUploadPdfSchema } from './gamehelper.types'


export async function createGamehelperUploadMutation(params: {
    gamehelperUploadPDF: CreateGamehelperUploadPdfSchema
}) {
    let body: FormData | string | undefined = undefined;
    body = new FormData();

    if (params.gamehelperUploadPDF.file) {
        body.append('file', params.gamehelperUploadPDF.file);
    }
    if (params.gamehelperUploadPDF.scanned_PDF) {
        body.append('scanned_PDF', params.gamehelperUploadPDF.scanned_PDF.toString());
    }
    if (params.gamehelperUploadPDF.best_response) {
        body.append('response', params.gamehelperUploadPDF.best_response);
    }

    return createFormMutation({
        request: {
            url: gamehelperBaseUrl(`/game-helper-upload-pdf`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
            body: body,
        },
        response: {
            contract: zodContract(CreateGamehelperUploadPDFResponseSchema),
            mapData: ({ best_response }) =>
                mapCreateGamehelperUpload(params.gamehelperUploadPDF, best_response),
        },
    })
}

export async function createGamehelperLineMutation(params: {
    gamehelperLine: CreateGamehelperLineDto
}) {
    let body: FormData | string | undefined = undefined;
    body = new FormData();

    if (params.gamehelperLine.file_name) {
        body.append('file_name', params.gamehelperLine.file_name);
    }
    if (params.gamehelperLine.question) {
        body.append('question', params.gamehelperLine.question);
    }
    if (params.gamehelperLine.best_response) {
        body.append('response', params.gamehelperLine.best_response);
    }
    return createFormMutation({
        request: {
            url: gamehelperBaseUrl(`/game-helper-question`),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
            body: body,
        },
        response: {
            contract: zodContract(CreateGamehelperLineResponseSchema),
            mapData: ({ best_response }) =>
                mapCreateGamehelperLine(params.gamehelperLine, best_response),
        },
    })
}