import React from "react";
import * as _Builtin from "../_Builtin";


export function PopupNoAccess({
    as: _Component = _Builtin.Block,
}) {
    return (
        <_Component className="popup-overlay" tag="div" >
            <_Builtin.Block
                className="popup-box margin-medium rounded-8 overflow-hidden"
                tag="div"
            >
                <_Builtin.Block
                    className="popup-top background-color-blue200 padding-xsmall"
                    tag="div"
                >
                    <_Builtin.Block
                        className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
                        tag="div"
                    >
                        {""}
                    </_Builtin.Block>
                    <_Builtin.Heading className="text-align-center" tag="h2">
                        {"Your trial has expired!"}
                    </_Builtin.Heading>
                </_Builtin.Block>
                <_Builtin.Block
                    className="popup-body background-blue-150 padding-small"
                    tag="div"
                >
                    <_Builtin.Paragraph className="text-align-center text-color-light-grey margin-bottom margin-xxsmall">
                        {
                            "You've reached the end of your free trial. If you have any questions or requests, please contact us at:"
                        }
                    </_Builtin.Paragraph>
                    <_Builtin.Block
                        className="text-weight-bold text-align-center"
                        tag="div"
                    >
                        <a className="text-weight-bold text-align-center" href="mailto:bastien.s@xandimmersion.com">bastien.s@xandimmersion.com</a>.
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    );
}
