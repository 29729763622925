import * as _Builtin from '../_Builtin'


export function IntegrationUnityModules({
    as: _Component = _Builtin.Section,

}) {
    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className='container-large'
                tag="div"
            >
                <_Builtin.Block className='background-color-blue150 rounded-8' tag="div">
                    <_Builtin.Block className="animation-cards flex-center" tag="div">
                        <_Builtin.Block className="textposition" tag="div">
                            <_Builtin.Block className="logoposition" tag="div">
                                <_Builtin.Image
                                    className="logo-cover"
                                    id="w-node-c8719506-7b47-3944-6d49-76eb5cef14ce-5cef14cd"
                                    loading="lazy"
                                    width="auto"
                                    height="auto"
                                    alt=""
                                    src='/images/unity.png'
                                />
                            </_Builtin.Block>
                            <_Builtin.Heading className='flex-center'>
                                {"Integration in Unity"}
                            </_Builtin.Heading>
                            <_Builtin.Paragraph className="flex-center text-color-light-grey">
                {
                    'It is coming soon. Contact us for more info at'
                } 
                &nbsp;
                <a href="mailto:bastien.s@xandimmersion.com">
                    bastien.s@xandimmersion.com
                </a>
                &nbsp;
                {
                    'and someone from our team will answer to you!'
                }
                </_Builtin.Paragraph>
                        </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
