import { CreateTeam, DisplayUsers, RemoveTeam } from 'devlink';
import { sessionQueries } from 'entities/session';
import { sessionStore } from 'entities/session/session.model';
import { AdminContext } from 'pages/admin/admin-page.model';
import { useContext, useLayoutEffect, useState } from 'react';

type SectionAdminContainerProps = {
    selectedTeam: Team
}

type MultiSelectProps = {
    value: string
    label: string
}

export type Team = {
    name: string
    is_owner: number
    is_admin: number
    can_manage_models: number
    can_manage_npcs: number
    can_manage_datasets: number
    can_download_ariel: number
    can_download_diagen: number
    can_download_gepetto: number
    valid_until: string
}

export function SectionAdminContainer({
    selectedTeam,
}: SectionAdminContainerProps) {
    const { adminStore, setIsConfirmationDeleteOpen, setIsConfirmationCreateOpen, setIsConfirmationWarningOpen, setCreateTeamName, setIsConfirmationAddOpen, setIsConfirmAccessRightsOpen, setTargetTeamsList, setUserAccessRights, setUpdateUserName} = useContext(AdminContext)
    const [teamOptions, setTeamOptions] = useState<MultiSelectProps[]>([])
    const [users, setUsers] = useState<any[]>([])
    const [teamName, setTeamName] = useState<string>()
    const {
        mutate: getUsers,
        isPending: getUsersPending,
        isError: isErrorUsers,
        data: responseUsers,
        isSuccess: isSuccessUsers,
        error: errorUsers,
    } = sessionQueries.useGetUsersMutation()

    
    // load the Admin store when the component mounts
    useLayoutEffect(() => {
        adminStore.getState().load()
        getUsers()
    }, [])

    useLayoutEffect(() => {
        if (isSuccessUsers) {
            setUsers(Object.keys(responseUsers).map((key) => responseUsers[key]) )
        }
        if (isErrorUsers) {
            console.log('errorUsers', errorUsers)
            // set users to empty array if there is an error
            setUsers([])
        }
    }, [isSuccessUsers, isErrorUsers])


    // save the Admin store when the component unmounts
    useLayoutEffect(() => {
        return () => {
            adminStore.getState().save()
        }
    }, [])

    useLayoutEffect(() => {
        getUsers()
        const currentState = sessionStore.getState()
        const filteredTeams =
            currentState.teams?.filter(
                (team) => team.name !== selectedTeam?.name
            ) || []
        setTeamOptions(
            filteredTeams.map((team, index) => ({
                label: team.name,
                value: index.toString(),
            }))
        )
    }, [selectedTeam])

    return (
        <>  
            <DisplayUsers users={users} teamOptions={teamOptions} disabled={false} loading={getUsersPending} />
            <CreateTeam rdTeam={{
                    value: teamName,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const newTeamName = e.target.value
                        setTeamName(newTeamName)
                    },
                }}
                rdButtonCreate={{
                    onClick: () => {
                        if (!teamName || teamName === '') {
                            setIsConfirmationWarningOpen?.(true)
                        } else {
                            setCreateTeamName?.(teamName)
                            setIsConfirmationCreateOpen?.(true)
                        }
                    },
                }}
                />
            <RemoveTeam
                rdButtonRemove={{
                    onClick: () => {
                        
                        setIsConfirmationDeleteOpen?.(true)
                    },
                }}
                />
        </>
    )
}