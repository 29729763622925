import { HeaderLayout } from 'devlink'
import { characterStore, Team } from 'entities/character/character.model'
import { useEffect, useState } from 'react'
import {
    AdminContainer,
    PageHeaderAdmin,
    PopupAdminAccessRights,
    PopupAdminAddMember,
    PopupAdminCreate,
    PopupAdminDelete,
    PopupAdminWarning,
} from 'widgets/admin'
import { AdminProvider } from './admin-page.model'

export function AdminPage() {
    const initialTeam = {
        name: '',
        is_owner: 0,
        is_admin: 0,
        can_manage_models: 0,
        can_manage_npcs: 0,
        can_manage_datasets: 0,
        can_download_ariel: 0,
        can_download_diagen: 0,
        can_download_gepetto: 0,
        valid_until: '',
    }
    const [selectedTeam, setSelectedTeam] = useState<Team>(characterStore.getState().currentTeam || initialTeam)

    useEffect(() => {
        const setComponentStateFromStore = (state: any) => {
            setSelectedTeam(state.currentTeam || initialTeam);
        };
    
        // Manually set the initial state from the store
        const initialState = characterStore.getState();
        setComponentStateFromStore(initialState);
    
        // Subscribe to future store changes
        const unsubscribe = characterStore.subscribe((state) => {
            if (state.currentTeam) {
                setComponentStateFromStore(state);
            }
        });
    
        // Cleanup function to unsubscribe
        return () => {
            unsubscribe();
        };
        
    }, [])

    return (
        <AdminProvider>
            <HeaderLayout
                slotHeader={<PageHeaderAdmin />}
                slotHeaderLayout={
                    <>
                        <AdminContainer selectedTeam={selectedTeam}/>
                    </>
                }
            />
            <PopupAdminDelete />
            <PopupAdminCreate />
            <PopupAdminWarning />
            <PopupAdminAccessRights selectedTeam={selectedTeam.name}/>
            <PopupAdminAddMember />
        </AdminProvider>
    )
}
