import { GamehelperLines, GamehelperUploadsPdf } from 'entities/gamehelper/gamehelper.types'
import { StateCreator } from 'zustand'

export type State = {
    file: File
    file_name: string
    question: string
    chunk_max_tokens: number
    top_k: number
    llama_road_params:  {
        n_gpu_layers: number
        n_ctx: number
        n_batch: number
    }
    llama_generation_params: {
        max_tokens: number
    }
    gamehelperLines: GamehelperLines
    gamehelperUploadsPDF: GamehelperUploadsPdf
}

export type Actions = {
    setFile: (state: File) => void
    setFileName: (state: string) => void
    setQuestion: (state: string) => void
    setChunkMaxTokens: (state: number) => void
    setTopK: (state: number) => void
    setLlamaRoadParams: (state: {
        n_gpu_layers: number
        n_ctx: number
        n_batch: number
    }) => void
    setLlamaGenerationParams: (state: {
        max_tokens: number
    }) => void
    setGamehelperLines: (state: GamehelperLines) => void
    setGamehelperUploadsPDF: (state: GamehelperUploadsPdf) => void
}

export type GamehelperState = State & Actions

export const createGamehelperSlice =
    (
        initialState: State
    ): StateCreator<
        GamehelperState,
        [['zustand/devtools', never]],
        [],
        GamehelperState
    > =>
    (set) => ({
        ...initialState,
        setFile: (file) => set({ file }),
        setFileName: (file_name) => set({ file_name }),
        setQuestion: (question) => set({ question }),
        setChunkMaxTokens: (chunk_max_tokens) => set({ chunk_max_tokens }),
        setTopK: (top_k) => set({ top_k }),
        setLlamaRoadParams: (llama_road_params) => set({ llama_road_params }),
        setLlamaGenerationParams: (llama_generation_params) => set({ llama_generation_params }),
        setGamehelperLines: (gamehelperLines) => set({ gamehelperLines }),
        setGamehelperUploadsPDF: (gamehelperUploadsPDF) => set({ gamehelperUploadsPDF }),
    })
        