import { z } from 'zod'

export const CreateTeamSchema = z.object({
    message: z.string(),
})

export const DeleteTeamSchema = z.object({
    message: z.string(),
})

export const AddMemberSchema = z.object({
    message: z.string(),
})

export const EditUserSchema = z.object({
    message: z.string(),
})

