import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'
export function RemoveTeam({
    as: _Component = _Builtin.Section,
    rdButtonRemove,
}) {
    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block
                className="container-large padding-horizontal padding-small"
                tag="div"
            >
                <_Builtin.Block
                    className="section-title-wrapper margin-bottom margin-xsmall"
                    tag="div"
                >
                    <_Builtin.Heading tag="h2">
                        {'Delete Team'}
                    </_Builtin.Heading>
                </_Builtin.Block>
                <_Builtin.Block className="section-content-wrapper" tag="div">
                    <_Builtin.FormBlockLabel
                        className="text-size-small margin-bottom margin-xxsmall"
                        htmlFor="field"
                    >
                        {'Deleting this team will remove all associated data. This action cannot be undone.'}
                    </_Builtin.FormBlockLabel>
                    <_Builtin.Block className="button-group" tag="div">
                        <_Builtin.Link
                            className="button is-red"
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                            {...rdButtonRemove}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="&#xe919;" />
                                <ButtonText buttonText="Delete Team" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
