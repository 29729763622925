import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";

export function PopupConfirmCreate({
  as: _Component = _Builtin.Block,
  rpButtonCancel = {},
  rpButtonConfirm = {},
  rpClosePopup = {},
  visibility = true,
  title = "Restart dialogue?",
  content = "You are about to regenerate the dialogue. All dialogue will be replaced by new ones.",
  content2 = "Are you sure?",
  slotButtonCancel,
  slotButtonConfirm,
}) {
  return visibility ? (
    <_Component className="popup-overlay" tag="div" {...rpClosePopup}>
      <_Builtin.Block
        className="popup-box margin-medium rounded-8 overflow-hidden"
        tag="div"
      >
        <_Builtin.Block
          className="popup-top background-color-blue200 padding-xsmall"
          tag="div"
        >
          <_Builtin.Block
            className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            &#xe917;
          </_Builtin.Block>
          <_Builtin.Heading className="text-align-center" tag="h2">
            {title}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup" tag="div" {...rpClosePopup}>
            {""}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className="popup-body background-blue-150 padding-small"
          tag="div"
        >
          <_Builtin.Paragraph className="text-align-center text-color-light-grey margin-bottom margin-xxsmall">
            {content}
          </_Builtin.Paragraph>
          <_Builtin.Block
            className="text-weight-bold text-align-center"
            tag="div"
          >
            {content2}
          </_Builtin.Block>
          <_Builtin.Block className="button-group padding padding-xsmall" tag="div">
            <_Builtin.Link
              className="button is-large is-transparent"
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
              {...rpButtonCancel}
            >
              <_Builtin.Block className="slot-button" tag="div">
                {slotButtonCancel ?? (
                  <>
                    <ButtonIcon buttonIconText="" />
                    <ButtonText buttonText="Cancel" />
                  </>
                )}
              </_Builtin.Block>
            </_Builtin.Link>
            <_Builtin.Link
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
              {...rpButtonConfirm}
            >
              <_Builtin.Block className="slot-button" tag="div">
                {slotButtonConfirm ?? (
                  <>
                    <ButtonIcon buttonIconText="&#xe917;" />
                    <ButtonText buttonText="Create" />
                  </>
                )}
              </_Builtin.Block>
            </_Builtin.Link>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}
