import { useMutation } from '@tanstack/react-query'
import { AdminModel } from 'entities/admin'
import { StoreApi } from 'zustand'
import { addMemberToTeamMutation, createTeamMutation, deleteTeamMutation, editUserMutation } from './admin.api'


// TODO: ChatGPT: Considering the structure of the keys and their usage in the file, it seems like there might be a slight redundancy in the dialogLine key. It's essentially the same as the root key in this case.
const keys = {
    root: () => ['admin'],
    createTeam: () => [...keys.root(), 'createTeam'],
    deleteTeam: () => [...keys.root(), 'deleteTeam'],
    addMember: () => [...keys.root(), 'addMember'],
    editUser: () => [...keys.root(), 'editUser'],
}


export function useCreateTeamMutation(
    adminStore?: StoreApi<AdminModel.State>
) {
    return useMutation({
        mutationKey: keys.createTeam(),
        mutationFn: createTeamMutation,
        onSuccess: (data) => {
            console.log("Create team successful")
        },
    })
}

export function useDeleteTeamMutation(
    adminStore?: StoreApi<AdminModel.State>
) {
    return useMutation({
        mutationKey: keys.deleteTeam(),
        mutationFn: deleteTeamMutation,
        onSuccess: (data) => {
            console.log("Delete team successful")
        },
    })
}

export function useAddMemberMutation(
    adminStore?: StoreApi<AdminModel.State>
) {
    return useMutation({
        mutationKey: keys.addMember(),
        mutationFn: addMemberToTeamMutation,
        onSuccess: (data) => {
            console.log("Add member successful")
        },
    })
}

export function useEditUserMutation(
    adminStore?: StoreApi<AdminModel.State>
) {
    return useMutation({
        mutationKey: keys.editUser(),
        mutationFn: editUserMutation,
        onSuccess: (data) => {
            console.log("Edit user successful")
        },
    })
}