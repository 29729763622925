import { adminPageRoute } from 'pages/admin'
import { animationPageRoute } from 'pages/animation'
import { contactPageRoute } from 'pages/contact'
import { datasetPageRoute } from 'pages/dataset'
import { dialogPageRoute } from 'pages/dialog'
import { gamehelperPageRoute } from 'pages/gamehelper'
import { homePageRoute } from 'pages/home'
import { integrationPageRoute } from 'pages/integration'
import { GenericLayout, GuestLayout, NakedLayout } from 'pages/layouts'
import { loginPageRoute } from 'pages/login'
import { page404Route } from 'pages/page-404'
import { releasesPageRoute } from 'pages/release-notes'
import { rollPageRoute } from 'pages/roll'
import { voicePageRoute } from 'pages/voice'
import {
    RouterProvider,
    createBrowserRouter,
    redirect,
    useRouteError,
} from 'react-router-dom'
import { pathKeys } from 'shared/lib/react-router'

function BubbleError() {
    const error = useRouteError()
    if (error) throw error
    return null
}

const router = createBrowserRouter([
    {
        errorElement: <BubbleError />,
        children: [
            {
                element: <GenericLayout />,
                children: [
                    homePageRoute,
                    voicePageRoute,
                    dialogPageRoute,
                    rollPageRoute,
                    gamehelperPageRoute,
                    datasetPageRoute,
                    contactPageRoute,
                    animationPageRoute,
                    adminPageRoute,
                    integrationPageRoute,
                    releasesPageRoute,
                ],
            },
            {
                element: <GuestLayout />,
                children: [loginPageRoute],
            },
            {
                element: <NakedLayout />,
                children: [page404Route],
            },
            {
                loader: async () => redirect(pathKeys.page404()),
                path: '*',
            },
        ],
    },
])

export function BrowserRouter() {
    return <RouterProvider router={router} />
}
