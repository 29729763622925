import { createStore } from 'zustand'
import { DevtoolsOptions, devtools } from 'zustand/middleware'
import { voiceModel } from 'entities/voice'

const initialVoiceState: voiceModel.State = {
    selectedCharacter: undefined,
    selectedEmotion: undefined,
    selectedLanguage: undefined,
    voiceRecords: [{ sentence: '', convergence: 0.5, speed: 1, emotion: undefined}],
}

const initialFileState: voiceModel.FileState = {
    file: undefined,
    uuid: undefined,
    status: undefined,
    url: undefined,
    last_modified: undefined,
    progress: undefined,
}

const voiceStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'VoicePage VoiceStore',
}

const fileStoreDevtoolsOptions: DevtoolsOptions = {
    name: 'VoicePage FileStore',
}

export const fileStore = createStore<voiceModel.VoiceFileState>()(
    devtools(
        voiceModel.createFileSlice(initialFileState),
        fileStoreDevtoolsOptions
    )
)

export const voiceStore = createStore<voiceModel.VoiceState>()(
    devtools(
        voiceModel.createVoiceSlice(initialVoiceState),
        voiceStoreDevtoolsOptions
    )
)

