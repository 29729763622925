import {
    PopupGamehelperRestart,
    PopupGamehelperExport,
    PopupGamehelperBestPractice,
} from 'devlink'
import { GamehelperContext } from 'pages/gamehelper/gamehelper-page.model'
import React, { useContext, useEffect, useCallback } from 'react';

export function PopupGamehelperRestartContainer() {
    const { isModalRestartOpen, setIsModalRestartOpen } =
        useContext(GamehelperContext)


    const handleClosePopup = useCallback(() => {
        setIsModalRestartOpen?.(false);
    }, [setIsModalRestartOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupGamehelperRestart
            visibility={isModalRestartOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalRestartOpen?.(false)
                },
            }}
        />
    )
}


export function PopupGamehelperExportContainer() {
    const { isModalExportOpen, setIsModalExportOpen } =
        useContext(GamehelperContext)

        const handleClosePopup = useCallback(() => {
            setIsModalExportOpen?.(false);
        }, [setIsModalExportOpen]);
    
        useEffect(() => {
            const handleEscKey = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    handleClosePopup();
                }
            };
            document.addEventListener('keydown', handleEscKey);
            return () => {
                document.removeEventListener('keydown', handleEscKey);
            };
        }, [handleClosePopup]);
        
    return (
        <PopupGamehelperExport
            visibility={isModalExportOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault();
                    setIsModalExportOpen?.(false);
                },
            }}
        // rpButtonExport={{
        //     className: 'button is-disabled',
        // }}
        />
    )
}

export function PopupGamehelperBestPracticeContainer() {
    const {
        isModalBestPracticeOpen,
        setIsModalBestPracticeOpen,
        setIsBestPracticeTriggered,
    } = useContext(GamehelperContext)

    const handleClosePopup = useCallback(() => {
        setIsModalBestPracticeOpen?.(false);
    }, [setIsModalBestPracticeOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupGamehelperBestPractice
            visibility={isModalBestPracticeOpen}
            rpClosePopUp={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault();
                    setIsModalBestPracticeOpen?.(false);
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsModalBestPracticeOpen?.(false)
                    setIsBestPracticeTriggered?.(true)
                },
            }}
        />
    )
}