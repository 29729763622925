import {
    PopupConfirmCreate,
    PopupConfirmDelete,
    PopupConfirmWarning,
} from 'devlink';
import { useAddMemberMutation, useCreateTeamMutation, useDeleteTeamMutation, useEditUserMutation } from 'entities/admin/admin.queries';
import { characterStore } from 'entities/character/character.model';
import { AdminContext } from 'pages/admin/admin-page.model';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { sessionStore } from '../../entities/session/session.model';

export function PopupAdminConfirmDeleteContainer() {
    const { isConfirmationDeleteOpen, setIsConfirmationDeleteOpen, setRequestUpdate } =
        useContext(AdminContext)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        characterStore.getState().currentTeam?.name || null
    )

    const {
        mutate: deleteTeam,
        isPending: deleteTeamPending,
        isError: isErrorDeleteTeam,
        data: responseDeleteTeam,
        isSuccess: isSuccessDeleteTeam,
        error: errorDeleteTeam,
    } = useDeleteTeamMutation()

    useLayoutEffect(() => {
        if (isSuccessDeleteTeam) {
            console.log("Delete team successful", responseDeleteTeam)
            const data = sessionStore.getState().teams?.filter((team: any) => team.name !== selectedTeam)
            if (data){
                
                // set the current team to the first team in the list
                const currentState = sessionStore.getState()
                characterStore
                .getState()
                .updateCurrentTeam(
                    currentState.teams?.find((t) => t.name !== selectedTeam)
                )
                sessionStore.getState().updateTeams(data)
            }
        }
        if (isErrorDeleteTeam) {
            console.log('errorDeleteTeam', errorDeleteTeam)
        }
    }, [isSuccessDeleteTeam, isErrorDeleteTeam])


    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (selectedTeam && state.currentTeam?.name !== selectedTeam) {
                setSelectedTeam(state.currentTeam?.name)
            }
        })
    }, [selectedTeam])
    
    const handleClosePopup = useCallback(() => {
        setIsConfirmationDeleteOpen?.(false);
    }, [setIsConfirmationDeleteOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    function callDeleteTeam(name: string) {
        console.log("Delete team")
        deleteTeam({name: name})
    }

    return (
        <PopupConfirmDelete
            visibility={isConfirmationDeleteOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationDeleteOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationDeleteOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-small is-red',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    if (!selectedTeam) return      
                    callDeleteTeam(selectedTeam)
                },
            }}
            title="Delete Team"
            content={`You are about to delete this team. All data will be removed.`} 
            content2={`Are you sure you want to delete Team '${selectedTeam}'?`}
        />
    )
}

export function PopupAdminWarningContainer() {
    const { isConfirmationWarningOpen, setIsConfirmationWarningOpen, adminStore } =
        useContext(AdminContext)


    const handleClosePopup = useCallback(() => {
        setIsConfirmationWarningOpen?.(false);
    }, [setIsConfirmationWarningOpen]);

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    return (
        <PopupConfirmWarning
            visibility={isConfirmationWarningOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationWarningOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationWarningOpen?.(false)
                },
            }}
            title="Warning"
            content={`You need to give your team a name.`} 
            content2={`Try again!`}
        />
    )
}

export function PopupAdminConfirmCreateContainer() {
    const { isConfirmationCreateOpen, setIsConfirmationCreateOpen, createTeamName, setCreateTeamName, adminStore } =
        useContext(AdminContext)
    
    const handleClosePopup = useCallback(() => {
        setIsConfirmationCreateOpen?.(false);
    }, [setIsConfirmationCreateOpen]);

    const {
        mutate: createTeam,
        isPending: createTeamPending,
        isError: isErrorCreateTeam,
        data: responseCreateTeam,
        isSuccess: isSuccessCreateTeam,
        error: errorCreateTeam,
    } = useCreateTeamMutation()

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    useLayoutEffect(() => {
        if (isSuccessCreateTeam) {
            console.log("Create team successful", responseCreateTeam)
            // const data of type team
            const data = sessionStore.getState().teams
            // add the new team to the list of teams
            if (data){
                data?.push({
                    name: createTeamName,
                    is_owner: 1,
                    is_admin: 1,
                    can_manage_models: 1,
                    can_manage_npcs: 1,
                    can_manage_datasets: 1,
                    valid_until: '0000-00-00 00:00:00',
                    valid_from: '0000-00-00 00:00:00',
                    can_download_gepetto: 1,
                    can_download_ariel: 1,
                    can_download_diagen: 1,
                })
                
                // sort data by name
                data.sort((a: any, b: any) => {
                    return a.name.localeCompare(b.name)
                })

                // update data in the store
                sessionStore.getState().updateTeams(data)
                window.location.reload()
            }
        }
        if (isErrorCreateTeam) {
            console.log('errorCreateTeam', errorCreateTeam)
        }
    }, [isSuccessCreateTeam, isErrorCreateTeam])

    function callCreateTeam(createTeamName: string) {
        console.log("Create team")
        createTeam({ name: createTeamName })
    }

    return (
        <PopupConfirmCreate
            visibility={isConfirmationCreateOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationCreateOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationCreateOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-small is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    callCreateTeam(createTeamName)
                },
            }}
            title="Create Team"
            content={`You are about to create a new team.`} 
            content2={`Are you sure you want to create Team '${createTeamName}'?`}
        />
    )
}

interface PopupAdminConfirmAccessRightsContainerProps {
    selectedTeam: string;
}

export function PopupAdminConfirmAccessRightsContainer({
    selectedTeam,
}: PopupAdminConfirmAccessRightsContainerProps) {
    // const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
    //     characterStore.getState().currentTeam?.name || null
    // )
    const { isConfirmAccessRightsOpen, setIsConfirmAccessRightsOpen, userAccessRights, updateUserName,  } =
        useContext(AdminContext)
    
    const handleClosePopup = useCallback(() => {
        setIsConfirmAccessRightsOpen?.(false);
    }, [setIsConfirmAccessRightsOpen]);

    const {
        mutate: editUser,
        isPending: editUserPending,
        isError: isErrorEditUser,
        data: responseEditUser,
        isSuccess: isSuccessEditUser,
        error: errorEditUser,
    } = useEditUserMutation()

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    useLayoutEffect(() => {
        if (isSuccessEditUser) {
            console.log("Edit User successful", responseEditUser)

        }
        if (isErrorEditUser) {
            console.log('errorEditUser', errorEditUser)
        }
    }, [isSuccessEditUser, isErrorEditUser])

    function callChangeAccessRights(
        uid: string,
        is_admin: boolean,
        is_owner: boolean,
        can_manage_models: boolean,
        can_manage_npcs: boolean,
        can_manage_datasets: boolean,
        can_download_gepetto: boolean,
        can_download_ariel: boolean,
        can_download_diagen: boolean,
        valid_until: string,
        valid_from: string,
        team: string,
    ) {
        console.log("Edit User")
        editUser({
            uid: uid,
            is_admin: is_admin,
            is_owner: is_owner,
            can_manage_models: can_manage_models,
            can_manage_npcs: can_manage_npcs,
            can_manage_datasets: can_manage_datasets,
            can_download_gepetto: can_download_gepetto,
            can_download_ariel: can_download_ariel,
            can_download_diagen: can_download_diagen,
            valid_until: valid_until,
            valid_from: valid_from,
            team: team,
        })
    }

    useLayoutEffect(() => {
        if (isSuccessEditUser) {
            console.log("Edit User successful", responseEditUser)
            window.location.reload()
        }
        if (isErrorEditUser) {
            console.log('errorEditUser', errorEditUser)
        }
    }, [isSuccessEditUser, isErrorEditUser])
    
    return (
        <PopupConfirmCreate
            visibility={isConfirmAccessRightsOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmAccessRightsOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmAccessRightsOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-small is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    if (!selectedTeam || !updateUserName) return
                    callChangeAccessRights(
                        updateUserName || '',
                        userAccessRights.is_admin,
                        userAccessRights.is_owner,
                        userAccessRights.can_manage_models,
                        userAccessRights.can_manage_npcs,
                        userAccessRights.can_manage_datasets,
                        userAccessRights.can_download_gepetto,
                        userAccessRights.can_download_ariel,
                        userAccessRights.can_download_diagen,
                        userAccessRights.valid_until,
                        userAccessRights.valid_from,
                        selectedTeam || '',
                    )
                },
            }}
            title="Edit User"
            content={`You are about to change User Attributes.`} 
            content2={`Are you sure you want to Edit the user '${updateUserName}'?`}
        />
    )
}

export function PopupAdminConfirmAddMemberTeamsContainer() {
    const { isConfirmationAddOpen, setIsConfirmationAddOpen, targetTeamsList, updateUserName  } =
        useContext(AdminContext)
    
    const handleClosePopup = useCallback(() => {
        setIsConfirmationAddOpen?.(false);
    }, [setIsConfirmationAddOpen]);

    const {
        mutate: addUser,
        isPending: addUserPending,
        isError: isErrorAddUser,
        data: responseAddUser,
        isSuccess: isSuccessAddUser,
        error: errorAddUser,
    } = useAddMemberMutation()

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [handleClosePopup]);

    useLayoutEffect(() => {
        if (isSuccessAddUser) {
            console.log("Edit User successful", responseAddUser)

        }
        if (isErrorAddUser) {
            console.log('errorEditUser', errorAddUser)
        }
    }, [isSuccessAddUser, isErrorAddUser])

    function callChangeAccessRights(
        uid: string,
        team: string,
    ) {
        console.log("Add User")
        addUser({
            uid: uid,
            team: team,
        })
    }

    return (
        <PopupConfirmCreate
            visibility={isConfirmationAddOpen}
            rpClosePopup={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationAddOpen?.(false)
                },
            }}
            rpButtonCancel={{
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    setIsConfirmationAddOpen?.(false)
                },
            }}
            rpButtonConfirm={{
                className: 'button is-small is-primary',
                onClick: (e: React.MouseEvent) => {
                    e.preventDefault()
                    if (!targetTeamsList || !updateUserName) return
                    for (let i = 0; i < targetTeamsList.length; i++) {
                        callChangeAccessRights(
                            updateUserName || '',
                            targetTeamsList[i] || '',
                        )
                    }
                },
            }}
            title="Add User Teams"
            content={`You are about to add the user to other teams.`} 
            content2={`Are you sure you want grant user '${updateUserName}' access to other teams?`}
        />
    )
}
