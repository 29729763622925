import React from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";

export function PopupVoiceExport({
  as: _Component = _Builtin.Block,
  rpClosePopUp = {},
  rpCheckboxMp3 = {},
  rpCheckboxWave = {},
  rpCheckboxOther = {},
  rpCheckboxText = {},
  visibility = true,
}) {
  return visibility ? (
    <_Component className="popup-overlay" tag="div" {...rpClosePopUp}>
      <_Builtin.Block
        className="popup-box margin-medium rounded-8 overflow-scroll-navbar"
        tag="div"
      >
        <_Builtin.Block
          className="popup-top background-color-blue200 padding-xsmall"
          tag="div"
        >
          <_Builtin.Block
            className="flex-center icon_ximm _2 margin-bottom margin-xxsmall"
            tag="div"
          >
            {""}
          </_Builtin.Block>
          <_Builtin.Heading className="text-align-center" tag="h2">
            {"Locked export feature"}
          </_Builtin.Heading>
          <_Builtin.Block className="close-popup" tag="div" {...rpClosePopUp}>
            {""}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className="popup-body background-blue-150 padding-small"
          tag="div"
        >
          <_Builtin.Paragraph className="text-align-center text-color-light-grey margin-bottom margin-small padding-horizontal">
            {
              "This feature is currently not available."
            }
          </_Builtin.Paragraph>
          <_Builtin.Paragraph className="margin-bottom margin-xsmall">
            {"List of extensions that will be available:"}
          </_Builtin.Paragraph>
          <_Builtin.Grid className="margin-bottom margin-small" tag="div">
            <_Builtin.FormCheckboxWrapper id="w-node-_49b49137-a163-2836-6d0d-2cfa2dec122f-2dec1220">
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="MP3"
                data-name="MP3"
                checked={false}
                required={false}
                id="MP3"
                form={{
                  type: "checkbox-input",
                  name: "MP3",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                {...rpCheckboxMp3}
              />
              <_Builtin.FormInlineLabel>{"MP3"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
            <_Builtin.FormCheckboxWrapper id="w-node-_49b49137-a163-2836-6d0d-2cfa2dec1233-2dec1220">
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="Wave"
                data-name="Wave"
                required={false}
                checked={false}
                id="Wave"
                form={{
                  type: "checkbox-input",
                  name: "Wave",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                {...rpCheckboxWave}
              />
              <_Builtin.FormInlineLabel>{"Wave"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
            <_Builtin.FormCheckboxWrapper>
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="Other"
                data-name="Other"
                required={false}
                checked={false}
                id="Other"
                form={{
                  type: "checkbox-input",
                  name: "Other",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                {...rpCheckboxOther}
              />
              <_Builtin.FormInlineLabel>{"..."}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
            <_Builtin.FormCheckboxWrapper>
              <_Builtin.FormCheckboxInput
                type="checkbox"
                name="Text"
                data-name="Text"
                required={false}
                checked={false}
                id="Text"
                form={{
                  type: "checkbox-input",
                  name: "Text",
                }}
                inputType="custom"
                customClassName="w-checkbox-input--inputType-custom"
                {...rpCheckboxText}
              />
              <_Builtin.FormInlineLabel>{"Text file"}</_Builtin.FormInlineLabel>
            </_Builtin.FormCheckboxWrapper>
          </_Builtin.Grid>
          <_Builtin.Link
            className="button is-large max-width-full is-secondary"
            button={false}
            block="inline"
            options={{
              href: "#",
            }}
            {...rpClosePopUp}
          >
            <_Builtin.Block className="slot-button" tag="div">
              <ButtonIcon buttonIconText="" />
              <ButtonText buttonText="Get it!" />
            </_Builtin.Block>
          </_Builtin.Link>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}
