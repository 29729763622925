import { useQuery } from '@tanstack/react-query'
import { characterQueries } from 'entities/character'
import { emotionQueries } from 'entities/emotion'
import { HeaderLayout } from 'devlink'
import { RollProvider } from './roll-page.model'
import {
    RollGenerationContainer,
    PageHeaderRollContainer,
    PopupRollRestartContainer,
    PopupRollExportContainer,
    PopupRollBestPracticesContainer,
} from 'widgets/roll'
import { useState, useLayoutEffect } from 'react'
import { Npc } from 'entities/character/character.types'
import { characterStore } from 'entities/character/character.model'

export function RollPage() {
    const [characters, setCharacters] = useState<Npc[]>(characterStore.getState().npcs || [])

    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (state.npcs && state.npcs !== characters) {
                setCharacters(state.npcs)
            }
        })
    }, [])

    return (
        <RollProvider>
            <HeaderLayout
                slotHeader={<PageHeaderRollContainer />}
                slotHeaderLayout={
                    <RollGenerationContainer
                        // isCharacterLoading={isCharacterLoading}
                        characters={characters}
                    />
                }
            />
            <PopupRollRestartContainer />
            <PopupRollExportContainer/>
            <PopupRollBestPracticesContainer/>
        </RollProvider>
    )
}
