import {
    DialogGenerateOptionsButtons,
    DialogGenerateOptionsSlider,
    DialogLine,
    DialogLineRegenerate,
    DialogLinesEmpty,
    DialogLinesList,
    DialogNpcSelectCard,
    DialogNpcSelectCardForm,
    SectionDialogGenerate,
    SectionDialogNpcSelect,
} from 'devlink'
import { characterTypes } from 'entities/character'
import { characterStore } from 'entities/character/character.model'
import { dialogModel } from 'entities/dialog'

// TODO: use barrel -> entities/dialog-line/index.ts
import {
    useCreateDialogLineMutation,
    useRegenerateDialogLineMutation,
} from 'entities/dialog-line/dialog-line.queries'
import { DialogLine as DialogLineType } from 'entities/dialog-line/dialog-line.types'

import { generateNextLine } from 'features/dialog-line/generate/generate-next-line'
import { regenerateOneLine } from 'features/dialog-line/generate/regenerate-line'
import { DialogContext } from 'pages/dialog/dialog-page.model'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Loader } from 'shared/ui/loader'
import { RadioButton } from 'shared/ui/radio'
import { SliderRange } from 'shared/ui/slider'

type SectionDialogNpcSelectContainerProps = {
    isLoading?: boolean
    characters: characterTypes.Npcs
    // characters: characterTypes.Characters
    temperature: number
    toxicity: number
    token: number
    language: string
    isGenerating: boolean
    setToxicity: (toxicity: number) => void
    setTemperature: (temperature: number) => void
    setToken: (token: number) => void
    setLanguage: (language: string) => void
}
export function SectionDialogNpcSelectContainer({
    isLoading,
    characters,
    temperature,
    toxicity,
    token,
    isGenerating,
    language,
    setToxicity,
    setTemperature,
    setToken,
    setLanguage,
}: SectionDialogNpcSelectContainerProps) {
    const { dialogStore } = useContext(DialogContext)

    const { setIsModalSwapOpen, isSwapTriggered, setIsSwapTriggered } =
        useContext(DialogContext)

    // load the dialog store when the component mounts
    useLayoutEffect(() => {
        dialogStore.getState().load()
    }, [])

    // save the dialog store when the component unmounts
    useLayoutEffect(() => {
        return () => {
            dialogStore.getState().save()
        }
    }, [])

    // const [selectedLanguage, setSelectedLanguage] = useState('en')

    const languageOptions = [
        {
            t: 'English',
            v: 'en',
        },
        {
            t: 'French',
            v: 'fr',
        },
        {
            t: 'Spanish',
            v: 'es',
        },
        {
            t: 'German',
            v: 'de',
        },
        {
            t: 'Italian',
            v: 'it',
        },
        {
            t: 'Portuguese',
            v: 'pt',
        },
    ]

    // the slider ranges from 0 to 100. The token ranges from 10 to 300. I need to transform it to fit the slider
    const transformValue = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((range_max - range_min) * (value - min)) / (max - min) + range_min
        )
    }

    // revert the transformation
    const revertTransform = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((value - range_min) * (max - min)) / (range_max - range_min) + min
        )
    }

    if (isLoading) {
        return (
            <SectionDialogNpcSelect
                slotDialogNpcSelectCards={
                    <>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    background: '#1E2338',
                                    height: '327px',
                                    borderRadius: '8px',
                                }}
                            >
                                <Loader size="parent" />
                            </div>
                        ))}
                    </>
                }
            />
        )
    }
    return (
        <>
            <SectionDialogNpcSelect
                slotDialogNpcSelectCards={
                    <>
                        {/* add a button that logs the dialogStore */}
                        {characters.length > 0 && (
                            <>
                                {dialogStore
                                    .getState()
                                    .characterStates.map((_, index) => (
                                        <DialogNpcSelectCardContainer
                                            key={index}
                                            index={index}
                                            swap={isSwapTriggered}
                                            characters={characters}
                                            setSwap={setIsSwapTriggered}
                                            setSwapIsOpen={setIsModalSwapOpen}
                                            onSelectCharacter={(character) => {
                                                dialogStore
                                                    .getState()
                                                    .setCharacter(
                                                        index,
                                                        character
                                                    )
                                                dialogStore.setState({
                                                    dialogLines: [],
                                                })
                                            }}
                                            onStartStateChange={(state) => {
                                                dialogStore
                                                    .getState()
                                                    .setCharacterStartState(
                                                        index,
                                                        state
                                                    )
                                            }}
                                            onEndStateChange={(state) => {
                                                dialogStore
                                                    .getState()
                                                    .setCharacterEndState(
                                                        index,
                                                        state
                                                    )
                                            }}
                                        />
                                    ))}
                            </>
                        )}
                    </>
                }
                rpButtonSwap={{
                    onClick: (e: Event) => {
                        e.preventDefault()

                        if (!isGenerating) {
                            // swap the characters
                            const temp =
                                dialogStore.getState().characterStates[0]
                            dialogStore
                                .getState()
                                .setCharacter(
                                    0,
                                    dialogStore.getState().characterStates[1]
                                        .character
                                )
                            dialogStore
                                .getState()
                                .setCharacter(1, temp.character)
                            // swap the start states
                            const tempStartState =
                                dialogStore.getState().characterStates[0]
                                    .startState
                            dialogStore
                                .getState()
                                .setCharacterStartState(
                                    0,
                                    dialogStore.getState().characterStates[1]
                                        .startState
                                )
                            dialogStore
                                .getState()
                                .setCharacterStartState(1, tempStartState)
                            // swap the end states
                            const tempEndState =
                                dialogStore.getState().characterStates[0]
                                    .endState
                            dialogStore
                                .getState()
                                .setCharacterEndState(
                                    0,
                                    dialogStore.getState().characterStates[1]
                                        .endState
                                )
                            dialogStore
                                .getState()
                                .setCharacterEndState(1, tempEndState)

                            // setIsSwapTriggered?.(true)
                            setIsModalSwapOpen?.(true)
                        }
                    },
                    className: `button is-secondary ${isGenerating && 'is-disabled'}`,
                }}
            />
            <DialogGenerateOptionsSlider
                slotTemperatureRange={
                    <SliderRange
                        value={temperature * 100}
                        onChange={(value: number) => {
                            setTemperature(value / 100)
                        }}
                    />
                }
                slotTokenRange={
                    <SliderRange
                        value={Math.round(
                            transformValue(token, 10, 200, 0, 100)
                        )}
                        onChange={(value: number) => {
                            setToken(
                                Math.round(
                                    revertTransform(value, 10, 200, 0, 100)
                                )
                            )
                        }}
                    />
                }
                slotToxicityRange={
                    <SliderRange
                        value={toxicity * 100}
                        onChange={(value: number) => {
                            setToxicity(value / 100)
                        }}
                    />
                }
                rpLanguage={{
                    label: 'Language',
                    options: languageOptions,
                    value: language,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedLanguage = e.target.value
                        setLanguage(selectedLanguage)
                    },
                }}
            />
        </>
    )
}

type DialogNpcSelectCardContainerProps = {
    index: number
    swap: boolean | undefined
    characters: characterTypes.Npcs
    setSwap: ((value: boolean) => void) | undefined
    setSwapIsOpen: ((value: boolean) => void) | undefined
    onSelectCharacter?: (character?: characterTypes.Npc) => void
    onStartStateChange?: (state: string) => void
    onEndStateChange?: (state: string) => void
}
function DialogNpcSelectCardContainer({
    index,
    swap,
    setSwap,
    characters,
    onSelectCharacter,
    onStartStateChange,
    onEndStateChange,
}: DialogNpcSelectCardContainerProps) {
    const { playerStore, dialogStore } = useContext(DialogContext)
    const [characterImage, setCharacterImage] = useState<string | null>(null)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        null
    )
    const [npcs, setNpcs] = useState<any[] | null | undefined>(null)
    const [selectedCharacter, setSelectedCharacter] =
        useState<characterTypes.Npc | null>(
            dialogStore.getState().characterStates[index].character || null
        )
    const [startState, setStartState] = useState(
        dialogStore.getState().characterStates[index].startState ||
            selectedCharacter?.defaultStartState ||
            ''
    )

    const [endState, setEndState] = useState(
        dialogStore.getState().characterStates[index].endState ||
            selectedCharacter?.defaultEndState ||
            ''
    )
    const [startStateLength, setStartStateLength] = useState(
        dialogStore.getState().characterStates[index].startState.length ||
            selectedCharacter?.defaultStartState.length ||
            0
    )
    const [endStateLength, setEndStateLength] = useState(
        dialogStore.getState().characterStates[index].endState.length ||
            selectedCharacter?.defaultEndState.length ||
            0
    )

    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (
                state.currentTeam?.name !== selectedTeam &&
                npcs !== state.npcs
            ) {
                setCharacterImage(null)
                setSelectedTeam(state.currentTeam?.name)
                setNpcs(state.npcs)
                setSelectedCharacter(null)
                setCharacterImage(null)
                dialogStore.getState().reset()
            }
        })
    }, [selectedTeam, npcs])

    useEffect(() => {
        if (selectedCharacter) {
            const savedStates = JSON.parse(
                localStorage.getItem('states') || '{}'
            )
            const temp_startState =
                savedStates[selectedCharacter.name]?.startStates[0] ||
                selectedCharacter.defaultStartState
            const temp_endState =
                savedStates[selectedCharacter.name]?.endStates[0] ||
                selectedCharacter.defaultEndState
            setStartState(temp_startState)
            setEndState(temp_endState)

            onStartStateChange?.(temp_startState)
            onEndStateChange?.(temp_endState)

            const currentState = characterStore.getState().images
            const characterImage = currentState?.find(
                (image) => image.name === selectedCharacter.name
            )
            setCharacterImage(characterImage?.image || null)
        }
    }, [selectedCharacter])

    useEffect(() => {
        if (swap) {
            const temp_character =
                characters.find(
                    (character) =>
                        character.id ===
                        dialogStore.getState().characterStates[index]?.character
                            ?.id
                ) || null
            setSelectedCharacter(temp_character)
            setSwap?.(false)
        }
    }, [swap])

    const defaultValue = {
        id: 0,
        name: 'Choose a NPC',
        image: '',
        voices: { neutral: '' },
        defaultStartState: '',
        defaultEndState: '',
    };

    const characterOptions = [
        ...characters?.map((character) => ({
            t: character?.name,
            v: character?.id.toString(),
        })),
    ]

    return (
        <>
            <DialogNpcSelectCard
                dialogNpcSelectCardImg={
                    selectedCharacter &&
                    selectedCharacter.name === 'You as a player'
                        ? playerStore.getState().gender === 'female'
                            ? '/images/you_female.png'
                            : '/images/you_male.png'
                        : characterImage || '/images/blank.png'
                    // : "/images/blank.png"
                }
                slotNpcCardForm={
                    <DialogNpcSelectCardForm
                        rpDialogNpcSelectCardDropdown={{
                            label: 'Choose a NPC',
                            value: selectedCharacter?.id.toString(),
                            name: selectedCharacter?.name.toString() || 'Choose a NPC',
                            options: characterOptions,
                            onChange: (event: any) => {
                                const character =
                                    characters.find(
                                        (character) =>
                                            character.id.toString() ===
                                            event.target.value
                                    ) || null
                                setSelectedCharacter(character)
                                onSelectCharacter?.(character || undefined)
                                if (character?.name === 'You as a player') {
                                    // set playerStore name and gender
                                    playerStore
                                        ?.getState()
                                        .setName('placeholder name')

                                    // set a random gender (male, female) by a random number
                                    playerStore
                                        ?.getState()
                                        .setGender(
                                            Math.random() < 0.5
                                                ? 'male'
                                                : 'female'
                                        )
                                }
                            },
                        }}
                        rpDialogNpcSelectCardStartState={{
                            style: {
                                textOverflow: 'ellipsis',
                            },
                            value: startState,
                            onChange: (event: any) => {
                                if (event.target.value.length <= 180) {
                                    setStartState(event.target.value)
                                    setStartStateLength(
                                        event.target.value.length
                                    )
                                    onStartStateChange?.(event.target.value)
                                }
                            },
                            disabled: selectedCharacter?.name === 'You as a player',
                        }}
                        rpDialogNpcSelectCardEndState={{
                            style: {
                                textOverflow: 'ellipsis',
                            },
                            value: endState,
                            onChange: (event: any) => {
                                if (event.target.value.length <= 180) {
                                    setEndState(event.target.value)
                                    onEndStateChange?.(event.target.value)
                                    setEndStateLength(event.target.value.length)
                                }
                            },
                            disabled: selectedCharacter?.name === 'You as a player',
                        }}
                        slotStartStateLength={startStateLength}
                        slotEndStateLength={endStateLength}
                        setStartState={setStartState}
                        setEndState={setEndState}
                    />
                }
            />
        </>
    )
}

type SectionDialogGenerateContainerProps = {
    characters: characterTypes.Npcs
    temperature: number
    toxicity: number
    token: number
    language: string
    setIsGenerating: (isGenerating: boolean) => void
}

export function SectionDialogGenerateContainer({
    characters,
    temperature,
    toxicity,
    token,
    language,
    setIsGenerating,
}: SectionDialogGenerateContainerProps) {
    const {
        setIsModalRegenerateOpen,
        isRegenerateTriggered,
        setIsRegenerateTriggered,
    } = useContext(DialogContext)

    const { dialogStore } = useContext(DialogContext)

    const [isComplete, setIsComplete] = useState(false)
    const [lines, setLines] = useState<DialogLineType[]>(
        dialogStore.getState().dialogLines
    )
    // selected characters
    const [selectedCharacters, setSelectedCharacters] = useState(
        dialogStore.getState().characterStates
    )

    // line generation
    const [linesToGenerate, setLinesToGenerate] = useState(0)
    const [shouldScroll, setShouldScroll] = useState(true)

    // end regeneration
    const [startEndIndex, setStartEndIndex] = useState(-1)

    // line generation
    const {
        mutate: createDialogLine,
        isPending: isGeneratingLine,
        isSuccess: isSuccessCreateLine,
    } = useCreateDialogLineMutation(dialogStore)

    // subscribe to state changes from dialogStore
    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            // set selected characters
            setSelectedCharacters(state.characterStates)
        })
    }, [dialogStore])

    // line regeneration
    const {
        mutate: regenerateDialogLine,
        isPending: isRegeneratingEnd,
        data: regeneratedLine,
    } = useRegenerateDialogLineMutation()

    // subscribe to state changes
    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            computeIsComplete(state)
            setLines(state.dialogLines)
        })
        computeIsComplete(dialogStore.getState())
    }, [dialogStore])

    // listen to line generation success
    useLayoutEffect(() => {
        if (isSuccessCreateLine) {
            setLinesToGenerate(linesToGenerate - 1)
            if (shouldScroll) {
                window.scrollTo(0, document.body.scrollHeight)
            }

            // if any of the characters is the player, generate an empty line for the player
            if (
                selectedCharacters.some(
                    (character) =>
                        character.character?.name === 'You as a player'
                )
            ) {
                dialogStore?.setState({
                    dialogLines: [
                        ...lines,
                        {
                            npc_name: 'You as a player',
                            player_name: '',
                            startState: '',
                            endState: '',
                            question: lines[lines.length - 1].response,
                            response: '',
                            maxToken: 0,
                            temperature: 0,
                            toxicity: 0,
                            shouldEnd: false,
                            language: language,
                        },
                    ],
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreateLine])

    // listen to line regeneration success
    useLayoutEffect(() => {
        if (regeneratedLine) {
            const newLines = [...lines]
            newLines[startEndIndex] = regeneratedLine
            dialogStore.setState({
                dialogLines: newLines,
            })
            setStartEndIndex((index) => index + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regeneratedLine])

    // generate next line if needed
    useLayoutEffect(() => {
        if (linesToGenerate > 0) {
            generateNextLine(createDialogLine, dialogStore, {
                maxToken: token,
                toxicity: toxicity,
                temperature: temperature,
                shouldEnd: lines.length >= dialogStore.getState().startEndIndex,
                language: language,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linesToGenerate])

    // regenerate end if needed
    useLayoutEffect(() => {
        if (startEndIndex >= 0 && startEndIndex < lines.length) {
            regenerateOneLine(regenerateDialogLine, dialogStore, {
                lineIndex: startEndIndex,
                maxToken: lines[startEndIndex].maxToken,
                toxicity: lines[startEndIndex].toxicity,
                temperature: lines[startEndIndex].temperature,
                shouldEnd: lines.length - 1 > startEndIndex,
                language: language,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startEndIndex])

    // compute is the form is complete
    function computeIsComplete(state: dialogModel.State) {
        if (
            state.characterStates.every((characterState) => {
                return characterState.character !== undefined
            })
        ) {
            setIsComplete(true)
        } else {
            setIsComplete(false)
        }
    }

    // ask to regenerate all the dialog
    useLayoutEffect(() => {
        if (isRegenerateTriggered) {
            dialogStore.setState({
                dialogLines: [],
            })
            if (selectedCharacters[0].character?.name === 'You as a player') {
                dialogStore?.setState({
                    dialogLines: [
                        {
                            npc_name: 'You as a player',
                            player_name: '',
                            startState: '',
                            endState: '',
                            question:
                                lines.length > 0
                                    ? lines[lines.length - 1].response
                                    : '',
                            response: '',
                            maxToken: 0,
                            toxicity: 0,
                            temperature: 0,
                            shouldEnd: false,
                            language: language,
                        },
                    ],
                })
                setIsRegenerateTriggered?.(false)
            } else if (
                selectedCharacters[1].character?.name === 'You as a player'
            ) {
                generateLines(1, dialogStore.getState().startEndIndex)
                setIsRegenerateTriggered?.(false)
            } else {
                generateLines(
                    lines.length,
                    dialogStore.getState().startEndIndex
                )
                setIsRegenerateTriggered?.(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRegenerateTriggered])

    // ask to generate some lines
    function generateLines(numLines: number, startEndIndex?: number) {
        setShouldScroll(true)
        if (
            selectedCharacters[lines.length % 2].character?.name ===
            'You as a player'
        ) {
            dialogStore?.setState({
                dialogLines: [
                    ...lines,
                    {
                        npc_name: 'You as a player',
                        player_name: '',
                        startState: '',
                        endState: '',
                        question:
                            lines.length > 0
                                ? lines[lines.length - 1].response
                                : '',
                        response: '',
                        maxToken: 0,
                        toxicity: 0,
                        temperature: 0,
                        shouldEnd: false,
                        language: language,
                    },
                ],
            })
        } else {
            setLinesToGenerate(numLines)
            dialogStore.setState({
                startEndIndex:
                    startEndIndex !== undefined ? startEndIndex : numLines / 2,
            })
        }
    }

    // ask to regenerate the end of the conversation
    function regenerateEnd(index: number) {
        setStartEndIndex(index)
    }

    useLayoutEffect(() => {
        if (!isGeneratingLine && linesToGenerate <= 1) {
            setIsGenerating(false)
        } else {
            setIsGenerating(true)
        }
    }, [isGeneratingLine])

    return (
        <>
            <SectionDialogGenerate
                slotDialogGenerate={
                    <>
                        {lines.length === 0 && (
                            <>
                                <DialogGenerateOptionsButtonsContainer
                                    isEnabled={isComplete && !isGeneratingLine}
                                    onClick={generateLines}
                                />
                                {isGeneratingLine ? (
                                    <div
                                        style={{
                                            background: '#1E2338',
                                            height: '150px',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Loader size="parent" />
                                    </div>
                                ) : (
                                    <DialogLinesEmpty />
                                )}
                            </>
                        )}
                        {lines.length !== 0 && (
                            <DialogLinesList
                                slotDialogueLines={
                                    <>
                                        {lines
                                            .filter(
                                                (_, index) =>
                                                    startEndIndex < 0 ||
                                                    startEndIndex > index
                                            )
                                            .map((_, index) => (
                                                <DialogLineContainer
                                                    key={index}
                                                    index={index}
                                                    isPending={isGeneratingLine}
                                                    generateEnd={regenerateEnd}
                                                    language=""
                                                    toxicity={
                                                        lines[index].toxicity
                                                    }
                                                    setToxicity={(
                                                        toxicity: number
                                                    ) => {
                                                        const newLines = [
                                                            ...lines,
                                                        ]
                                                        newLines[
                                                            index
                                                        ].toxicity = toxicity
                                                        dialogStore.setState({
                                                            dialogLines:
                                                                newLines,
                                                        })
                                                        return toxicity
                                                    }}
                                                    temperature={
                                                        lines[index].temperature
                                                    }
                                                    setTemperature={(
                                                        temperature: number
                                                    ) => {
                                                        const newLines = [
                                                            ...lines,
                                                        ]
                                                        newLines[
                                                            index
                                                        ].temperature =
                                                            temperature
                                                        dialogStore.setState({
                                                            dialogLines:
                                                                newLines,
                                                        })
                                                        return temperature
                                                    }}
                                                />
                                            ))}
                                        {(isGeneratingLine ||
                                            isRegeneratingEnd) && (
                                            <div
                                                style={{
                                                    background: '#1E2338',
                                                    height: '150px',
                                                    borderRadius: '8px',
                                                }}
                                            >
                                                <Loader size="parent" />
                                            </div>
                                        )}
                                    </>
                                }
                                rpButtonGenerateResponse={{
                                    onClick: (e: Event) => {
                                        e.preventDefault()
                                        !isGeneratingLine &&
                                            generateLines(
                                                1,
                                                dialogStore.getState()
                                                    .startEndIndex
                                            )
                                    },
                                    className: `button ${isGeneratingLine && 'is-disabled'}`,
                                }}
                                rpButtonRegenerateAll={{
                                    onClick: (e: Event) => {
                                        e.preventDefault()
                                        !isGeneratingLine &&
                                            setIsModalRegenerateOpen?.(true)
                                    },
                                    className: `button is-secondary ${isGeneratingLine && 'is-disabled'}`,
                                }}
                            />
                        )}
                    </>
                }
            />
        </>
    )
}

type DialogLineContainerProps = {
    index: number
    isPending: boolean
    generateEnd: (index: number) => void
    language: string
    temperature: any
    toxicity: any
    setToxicity: (toxicity: number) => void
    setTemperature: (temperature: number) => number
}
function DialogLineContainer({
    index,
    isPending,
    generateEnd,
    temperature,
    language,
    toxicity,
    setToxicity,
}: DialogLineContainerProps) {
    const { dialogStore, playerStore } = useContext(DialogContext)
    const lines = dialogStore.getState().dialogLines
    const line = lines[index]
    // the slider ranges from 0 to 100. The token ranges from 10 to 300. I need to transform it to fit the slider
    const transformValue = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((range_max - range_min) * (value - min)) / (max - min) + range_min
        )
    }

    // revert the transformation
    const revertTransform = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((value - range_min) * (max - min)) / (range_max - range_min) + min
        )
    }
    const [player, setPlayer] = useState(playerStore?.getState())

    // start end radio button condition
    const [isStartEndSelected, setIsStartEndSelected] = useState(
        dialogStore.getState().startEndIndex === index
    )

    // listen to start end radio change
    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            setIsStartEndSelected(state.startEndIndex === index)
        })
    }, [])

    // listen to start end radio change
    useLayoutEffect(() => {
        playerStore?.subscribe((state) => {
            setPlayer(state)
        })
    }, [])

    // line regeneration
    const [regeneratingLineIndex, setRegeneratingLineIndex] = useState(-1)

    // line regeneration
    const {
        mutate: regenerateDialogLine,
        isPending: isRegenerating,
        data: regeneratedLine,
    } = useRegenerateDialogLineMutation()

    // listen to line regeneration success
    useLayoutEffect(() => {
        if (regeneratedLine) {
            const newLines = [...lines]
            newLines[regeneratingLineIndex] = regeneratedLine
            dialogStore.setState({
                dialogLines: newLines,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regeneratedLine])

    // ask to regenerate a line
    function regenerateLine(
        lineIndex: number,
        temperature: number,
        maxToken: number,
        toxicity: number,
        language: string
    ) {
        setRegeneratingLineIndex(lineIndex)
        regenerateOneLine(regenerateDialogLine, dialogStore, {
            lineIndex,
            maxToken: maxToken,
            toxicity: toxicity,
            temperature: temperature,
            shouldEnd: lineIndex >= dialogStore.getState().startEndIndex,
            language: language,
        })
    }

    return (
        <>
            <DialogLineRegenerate
                dialogLineRegenerateNumber={index + 1}
                slotRadioButton={
                    <RadioButton
                        onClick={() => {
                            dialogStore.setState({
                                startEndIndex: index,
                            })
                        }}
                        checked={isStartEndSelected}
                    />
                }
                rpStartEndFromHere={{
                    onClick: () => {
                        dialogStore.setState({
                            startEndIndex: index,
                        })
                    },
                }}
                startEndFromHereVisibility={!isStartEndSelected}
                startEndVisibility={isStartEndSelected}
                buttonVisibility={isStartEndSelected}
                rpRegenerateButton={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        generateEnd(index)
                    },
                    className: `button is-secondary ${isPending && 'is-disabled'}`,
                }}
            />
            {isRegenerating && regeneratingLineIndex === index ? (
                <div
                    style={{
                        background: '#1E2338',
                        height: '150px',
                        borderRadius: '8px',
                    }}
                >
                    <Loader size="parent" />
                </div>
            ) : (
                <DialogLine
                    dialogLineImage={
                        dialogStore.getState().characterStates[index % 2]
                            .character?.name === 'You as a player'
                            ? playerStore.getState().gender === 'female'
                                ? '/images/you_female.png'
                                : '/images/you_male.png'
                            : // : dialogStore.getState().characterStates[index % 2].character?.image || "/images/blank.png"
                              '/images/blank.png'
                    }
                    rpDialogLine={{
                        className: `dialogue-output-wrapper  ${index % 2 !== 0 && 'reverse-row'}`,
                    }}
                    //if character is "You as a player" then make disappear the content of the slot (i use the dialogStore and then put empty field or invalidate condition)
                    slotDialogLinePlayerName={
                        dialogStore.getState().characterStates[index % 2]
                            .character?.name === 'You as a player'
                            ? ''
                            : null
                    }
                    rpDialogLineInput={{
                        value: line.response,
                        onChange: (e: Event) => {
                            e.preventDefault()
                            const line =
                                dialogStore.getState().dialogLines[index]
                            line.response = (e.target as any).value
                            dialogStore.setState({
                                dialogLines: [
                                    ...dialogStore
                                        .getState()
                                        .dialogLines.slice(0, index),
                                    line,
                                    ...dialogStore
                                        .getState()
                                        .dialogLines.slice(index + 1),
                                ],
                            })
                        },
                    }}
                    rpDialogOutputTop={{
                        className: `dialogue-output-top margin-bottom margin-xxsmall max-width-full ${index % 2 !== 0 && 'reverse-row'}`,
                    }}
                    slotTemperatureRange={
                        <SliderRange
                            value={line.temperature * 100}
                            onChange={(value: number) => {
                                let newValue = value / 100
                                dialogStore.setState({
                                    dialogLines: [
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(0, index),
                                        { ...line, temperature: newValue },
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(index + 1),
                                    ],
                                })
                                line.temperature = newValue
                            }}
                        />
                    }
                    slotTokenRange={
                        <SliderRange
                            value={Math.round(
                                transformValue(line.maxToken, 10, 200, 0, 100)
                            )}
                            onChange={(value: number) => {
                                // Set the token in the store
                                dialogStore.setState({
                                    dialogLines: [
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(0, index),
                                        {
                                            ...line,
                                            maxToken: Math.round(
                                                revertTransform(
                                                    value,
                                                    10,
                                                    200,
                                                    0,
                                                    100
                                                )
                                            ),
                                        },
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(index + 1),
                                    ],
                                })
                                // update the line
                                line.maxToken = Math.round(
                                    revertTransform(value, 10, 200, 0, 100)
                                )
                            }}
                        />
                    }
                    slotToxicityRange={
                        <SliderRange
                            value={line.toxicity * 100}
                            onChange={(value: number) => {
                                let newValue = value / 100
                                dialogStore.setState({
                                    dialogLines: [
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(0, index),
                                        { ...line, toxicity: newValue },
                                        ...dialogStore
                                            .getState()
                                            .dialogLines.slice(index + 1),
                                    ],
                                })
                                line.toxicity = newValue
                            }}
                        />
                    }
                    rpDialogLineRegenerate={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            regenerateLine(
                                index,
                                line.temperature,
                                line.maxToken,
                                line.toxicity,
                                line.language
                            )
                        },
                    }}
                />
            )}
        </>
    )
}

type DialogGenerateOptionsButtonsContainerProps = {
    isEnabled?: boolean
    onClick: (numLines: number) => void
}
function DialogGenerateOptionsButtonsContainer({
    isEnabled = false,
    onClick,
}: DialogGenerateOptionsButtonsContainerProps) {
    const { dialogStore } = useContext(DialogContext)
    const [playerActive, setPlayerActive] = useState(false)
    const [selectedCharacters, setSelectedCharacters] = useState(
        dialogStore.getState().characterStates
    )

    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            // set selected characters
            setSelectedCharacters(state.characterStates)
        })
    }, [dialogStore])

    useLayoutEffect(() => {
        if (
            selectedCharacters.some(
                (character) => character.character?.name === 'You as a player'
            )
        ) {
            setPlayerActive(true)
        } else {
            setPlayerActive(false)
        }
    }, [selectedCharacters])

    return (
        <>
            <DialogGenerateOptionsButtons
                rpGenerateLeft={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(4)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateMiddle={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(6)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateRight={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(8)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateYouAsAPlayer={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(1)
                    },
                    className: `button is-xlarge is-secondary ${!playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
            />
        </>
    )
}
