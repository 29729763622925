import { HeaderLayout } from 'devlink'
import { useLayoutEffect, useState } from 'react'
import { PageHeaderGamehelper, GamehelperParams, PopupGamehelperBestPractice, PopupGamehelperExport, PopupGamehelperRestart } from 'widgets/gamehelper'
import { GamehelperProvider } from './gamehelper-page.model'

export function GamehelperPage() {
    return (
        <GamehelperProvider>
            <HeaderLayout
                slotHeader={<PageHeaderGamehelper />}
                slotHeaderLayout={
                <GamehelperParams/>
                }

            />
            <PopupGamehelperBestPractice/>
            <PopupGamehelperExport />
            <PopupGamehelperRestart />
        </GamehelperProvider>
    )
}
