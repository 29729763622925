import { useMutation } from '@tanstack/react-query'
import { dialogModel } from 'entities/dialog'
import { queryClient } from 'shared/lib/react-query'
import { StoreApi } from 'zustand'
import { createDialogLineMutation } from './dialog-line.api'
import { DialogLine } from './dialog-line.types'

const keys = {
    root: () => ['dialogLine'],
    dialogLine: () => [...keys.root(), 'dialogLine'],
    createDialogLine: () => [...keys.root(), 'createDialogLine'] as const,
}

export const dialogLineService = {
    queryKey: () => keys.dialogLine(),

    getCache: () =>
        queryClient.getQueryData<DialogLine>(dialogLineService.queryKey()),

    setCache: (dialogLine: DialogLine) =>
        queryClient.setQueryData(dialogLineService.queryKey(), dialogLine),

    removeCache: () =>
        queryClient.removeQueries({
            queryKey: dialogLineService.queryKey(),
        }),
}

export function useCreateDialogLineMutation(
    dialogStore?: StoreApi<dialogModel.State>
) {
    return useMutation({
        mutationKey: keys.createDialogLine(),
        mutationFn: createDialogLineMutation,
        onSuccess: (data) => {
            const currentDialogLines = dialogStore?.getState().dialogLines || []
            dialogStore?.setState({
                dialogLines: [...currentDialogLines, data],
            })
        },
    })
}

export function useRegenerateDialogLineMutation() {
    return useMutation({
        mutationKey: keys.createDialogLine(),
        mutationFn: createDialogLineMutation,
    })
}
