import { useMutation } from '@tanstack/react-query'
import { gamehelperModel } from 'entities/gamehelper'
import { queryClient } from 'shared/lib/react-query'
import { StoreApi } from 'zustand'
import { createGamehelperLineMutation, createGamehelperUploadMutation } from './gamehelper.api'
import { GamehelperLine, GamehelperUploadPdf } from './gamehelper.types'

const keys = {
    root: () => ['gamehelperLine'],
    gamehelperLine: () => [...keys.root(), 'gamehelperLine'],
    gamehelperUpload: () => [...keys.root(), 'gamehelperUpload'],
    createGamehelperUpload: () => [...keys.root(), 'createGamehelperUpload'] as const,
    createGamehelperLine: () => [...keys.root(), 'createGamehelperLine'] as const,
}

// Upload Mutations
export function useGamehelperUploadMutation() {
    return useMutation({
        mutationKey: keys.createGamehelperUpload(),
        mutationFn: createGamehelperUploadMutation,
    })
}

export function useCreateGamehelperUploadMutation(
    gamehelperStore?: StoreApi<gamehelperModel.State>
) {
    return useMutation({
        mutationKey: keys.createGamehelperUpload(),
        mutationFn: createGamehelperUploadMutation,
        onSuccess: (data) => {
            const currentGamehelperUploadsPDF = gamehelperStore?.getState().gamehelperUploadsPDF || []
            gamehelperStore?.setState({
                gamehelperUploadsPDF: [...currentGamehelperUploadsPDF, data],
            })
        },
    })
}

export const gamehelperUploadService = {
    queryKey: () => keys.gamehelperUpload(),

    getCache: () =>
        queryClient.getQueryData<GamehelperUploadPdf>(gamehelperUploadService.queryKey()),

    setCache: (gamehelperUpload: GamehelperUploadPdf) =>
        queryClient.setQueryData(gamehelperUploadService.queryKey(), gamehelperUpload),

    removeCache: () =>
        queryClient.removeQueries({
            queryKey: gamehelperUploadService.queryKey(),
        }),
}

/////////

export function useRegenerateGamehelperLineMutation() {
    return useMutation({
        mutationKey: keys.createGamehelperLine(),
        mutationFn: createGamehelperLineMutation,
    })
}

export const gamehelperLineService = {
    queryKey: () => keys.gamehelperLine(),

    getCache: () =>
        queryClient.getQueryData<GamehelperLine>(gamehelperLineService.queryKey()),

    setCache: (gamehelperLine: GamehelperLine) =>
        queryClient.setQueryData(gamehelperLineService.queryKey(), gamehelperLine),

    removeCache: () =>
        queryClient.removeQueries({
            queryKey: gamehelperLineService.queryKey(),
        }),
}

export function useCreateGamehelperLineMutation(
    gamehelperStore?: StoreApi<gamehelperModel.State>
) {
    return useMutation({
        mutationKey: keys.createGamehelperLine(),
        mutationFn: createGamehelperLineMutation,
        onSuccess: (data) => {
            const currentGamehelperLines = gamehelperStore?.getState().gamehelperLines || []
            gamehelperStore?.setState({
                gamehelperLines: [...currentGamehelperLines, data],
            })
        },
    })
}
